.about-box {
    display: flex;
    overflow: hidden;
    width: 100%;
    padding-bottom: 50px;
    min-height: 500px;
}
.about-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    width: 100%;

    background-color: #fff;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

#about-me-p {
    line-height: 2em;
    font-weight: 700;
    width: 75%;
}

h1 {
    padding: 0;
    margin: 0;
}
