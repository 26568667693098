.footer-box {
    display: flex;
    margin: 25px;

    width: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2em;
}
@media screen and (max-width: 380px) {
    .footer-box {
        font-size: 1em;
    }
}
