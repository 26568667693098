.project-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: rgb(137, 200, 255);
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-family: 'Cutive Mono', monospace;
    margin: 5px;
    min-height: 400px;
    width: 350px;
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: 5px 2px 0 black;
    padding: 10px;
    margin: 25px 10px;
    background-color: white;
}
#skill-list {
    width: auto;
    text-align: left;
    box-shadow: 2px 2px 0 black;
    border: 2px solid gray;
    column-count: 2;
}
#link-box {
    align-self: center;
    width: 50%;
    border: 2px solid black;
    box-shadow: 2px 2px 0 black;
    margin-bottom: 5px;
    padding: 5px;
}

#link-box:hover {
    padding: 10px;
}
